







































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { numeric, required, max } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import CryptoJS from 'crypto-js';

extend('max', {
  ...max,
  message: `Maximum code length must be 5`,
});
extend('numeric', {
  ...numeric,
  message: 'Invalid input provided',
});
extend('required', {
  ...required,
  message: 'Field is required',
});

const authModule = namespace('auth');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Login extends mixins(WidgetMixins) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @authModule.Action('login') actionLogin: any;
  username: string = '';
  password: string = '';
  bg: string = require('@/assets/images/wave-haikei.svg');
  show: boolean = false;

  code: string = '';

  verifyAccount(): void {
    this.$store.dispatch('auth/verifyAccount', {
      id: this.$route.query.id,
      code: this.code,
    });
  }

  resendVerificationCode(): void {
    this.$store.dispatch('auth/resendVerificationCode', {
      id: this.$route.query.id,
    });
  }

  created(): void {
    const encrypt = CryptoJS.AES.encrypt('message', '1234').toString();
    console.log(encrypt);
  }
}
